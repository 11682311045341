<template>
  <el-dialog
    :title="$t('bianji')"
    :visible.sync="visible"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    @close="closedialog"
    @open="open"
    :before-close="handleClose"
  >
    <el-divider content-position="left">{{ $t('yh.jbxx') }}</el-divider>
    <el-row>
      <el-col :span="8"
        ><b>{{ $t('yhm') }}：</b>{{ editform.username }}</el-col
      >
      <el-col :span="8"
        ><b>{{ $t('yh.shzt') }}：</b>
        <span v-if="editform.oauth === '1'">{{ $t('yh.sfrzz') }}</span>
        <span v-else-if="editform.oauth === '2'">{{ $t('yh.sfrz') }}</span>
        <span v-else-if="editform.oauth === '3'">{{ $t('yh.gjrzz') }}</span>
        <span v-else-if="editform.oauth === '4'">{{ $t('yh.gjrz') }}</span>
        <span v-else>{{ $t('yh.wrz') }}</span>
      </el-col>
      <el-col :span="8"
        ><b>{{ $t('yh.zhdlsj') }}：</b
        >{{ editform.lastlogintime || '暂未添加' }}</el-col
      >
    </el-row>
    <el-row>
      <el-col :span="8"
        ><b>{{ $t('yh.nicheng') }}：</b
        >{{ editform.nickname || '暂未添加' }}</el-col
      >
      <el-col :span="8"
        ><b>{{ $t('yh.sjh') }}：</b
        >{{ editform.username || '暂未添加' }}</el-col
      >
      <el-col :span="8"
        ><b>{{ $t('yh.youxiang') }}：</b
        >{{ editform.email || '暂未添加' }}</el-col
      >
    </el-row>

    <el-form ref="editref" :model="editform" label-width="120px">

      <el-divider content-position="left">{{ $t('yh.xyf') }}</el-divider>
      <el-form-item :label="$t('yh.xyf')" required>
        <el-input-number
          v-model="editform.credit_score"
          :min="1"
          :max="100"
        ></el-input-number>
      </el-form-item>


      <el-form-item label="亏损率" required>
        <el-input-number
          v-model="editform.loss"
          :min="0"
          :max="100"
        ></el-input-number>
      </el-form-item>


      <el-divider content-position="left">备注</el-divider>
      <el-form-item label="上级ID" prop="agentid">
        <el-input v-model="editform.agentid" :disabled="!editbz"></el-input>
      </el-form-item>
      <el-form-item label="备注" prop="realname">
        <el-input v-model="editform.remarks" :disabled="!editbz"></el-input>
      </el-form-item>

      <el-row style="text-align:center">
        <el-button @click.prevent="canbeizhu">{{ $t('yh.xiugai') }}</el-button>
      </el-row>


      <el-divider content-position="left">{{ $t('yh.sfbs') }}</el-divider>
       
      <el-form-item :label="$t('yh.sfbs')" required>
        <el-radio-group v-model="editform.is_real">
          <el-radio :label="'1'">{{ $t('yh.zsyh') }}</el-radio>
          <el-radio :label="'0'">{{ $t('yh.xnyh') }}</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item :label="$t('yh.fczh')" required>
        <el-radio-group v-model="editform.status">
          <el-radio :label="'0'">{{ $t('shi') }}</el-radio>
          <el-radio :label="'1'">{{ $t('fou') }}</el-radio>
        </el-radio-group>
      </el-form-item>
      <!-- <el-divider content-position="left">{{ $t('yh.sfrzxx') }}</el-divider>
      <el-form-item :label="$t('yh.zsxm')" prop="realname">
        <el-input v-model="editform.realname" :disabled="!editsf"></el-input>
      </el-form-item>
      <el-form-item :label="$t('yh.guojia')" prop="country">
        <el-input v-model="editform.country" :disabled="!editsf"></el-input>
      </el-form-item>
      <el-form-item :label="$t('yh.dizhi')" prop="address">
        <el-input v-model="editform.address" :disabled="!editsf"></el-input>
      </el-form-item>
      <el-row style="text-align:center">
        <el-button @click.prevent="caneditsf">{{ $t('yh.xiugai') }}</el-button>
      </el-row> -->

      <el-divider content-position="left">{{ $t('yh.gjrzxx') }}</el-divider>
      <el-form-item label="操作">
        <el-button @click="delrel">删除认证信息</el-button>
      </el-form-item>

      <el-form-item label="认证状态" required>
        <el-radio-group v-model="editform.oauth">
          <el-radio :label="'0'">未认证</el-radio>
          <el-radio :label="'1'">初级认证中</el-radio>
          <el-radio :label="'2'">初级已认证</el-radio>
          <el-radio :label="'3'">高级认证中</el-radio>
          <el-radio :label="'4'">高级已认证</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="国家" prop="country">
        <el-input v-model="editform.country" :disabled="!editgj"></el-input>
      </el-form-item>
      <el-form-item :label="$t('yh.zsxm')" prop="realname">
        <el-input v-model="editform.realname" :disabled="!editgj"></el-input>
      </el-form-item>
      <!--
      <el-form-item :label="$t('yh.zhiye')" prop="profession">
        <el-input v-model="editform.profession" :disabled="!editgj"></el-input>
      </el-form-item>-->

      <el-form-item label="证件号" prop="card">
        <el-input v-model="editform.card" :disabled="!editgj"></el-input>
      </el-form-item>
      <el-form-item label="邮箱" prop="email">
        <el-input v-model="editform.email" :disabled="!editgj"></el-input>
      </el-form-item>
      <!-- <el-form-item :label="$t('yh.shouru')" prop="income">
        <el-input v-model="editform.income" :disabled="!editgj"></el-input>
      </el-form-item> -->

      <!-- <el-form-item :label="$t('yh.lxdh')" prop="contact_number">
        <el-input
          v-model="editform.contact_number"
          :disabled="!editgj"
        ></el-input>
      </el-form-item> -->

      <el-form-item :label="$t('yh.sfzzm')" prop="identity_card_front_image">
        <el-upload
          class="avatar-uploader"
          :action="weburl + '/admin/user/uploadimg'"
          :show-file-list="false"
          :headers="headerobj"
          :on-success="uploadsfa"
        >
          <img
            v-if="editform.identity_card_front_image"
            :src="editform.identity_card_front_image"
            class="avatar"
          />
          <i v-else class="el-icon-plus avatar-uploader-icon"></i>
        </el-upload>
      </el-form-item>
      <el-form-item :label="$t('yh.sfzfm')" prop="identity_card_verso_image">
        <el-upload
          class="avatar-uploader"
          :action="weburl + '/admin/user/uploadimg'"
          :show-file-list="false"
          :headers="headerobj"
          :on-success="uploadsfb"
        >
          <img
            v-if="editform.identity_card_verso_image"
            :src="editform.identity_card_verso_image"
            class="avatar"
          />
          <i v-else class="el-icon-plus avatar-uploader-icon"></i>
        </el-upload>
      </el-form-item>

      <!-- <el-form-item
        :label="$t('yh.sczjzp')"
        prop="user_with_identity_card_image"
      >
        <el-upload
          class="avatar-uploader"
          :action="weburl + '/admin/user/uploadimg'"
          :show-file-list="false"
          :headers="headerobj"
          :on-success="uploadsc"
        >
          <img
            v-if="editform.user_with_identity_card_image"
            :src="editform.user_with_identity_card_image"
            class="avatar"
          />
          <i v-else class="el-icon-plus avatar-uploader-icon"></i>
        </el-upload>
      </el-form-item>
      <el-form-item :label="$t('yh.qtzm')" prop="other_image">
        <el-upload
          class="avatar-uploader"
          :action="weburl + '/admin/user/uploadimg'"
          :show-file-list="false"
          :headers="headerobj"
          :on-success="uploadqt"
        >
          <img
            v-if="editform.other_image"
            :src="editform.other_image"
            class="avatar"
          />
          <i v-else class="el-icon-plus avatar-uploader-icon"></i>
        </el-upload>
      </el-form-item> -->

      <el-row style="text-align:center">
        <el-button @click.prevent="caneditgj">{{ $t('yh.xiugai') }}</el-button>
      </el-row>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button type="primary" @click="submit">{{ $t('tijiao') }}</el-button>
      <el-button @click="handleClose">{{ $t('quxiao') }}</el-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
  data() {
    return {
      editsf: false,
      editgj: false,
      editbz:false,
      editform: {},
      // 设置图片上传的token
      headerobj: {
        Authorization: window.sessionStorage.getItem('token')
      }
    }
  },
  props: ['visible', 'uid'],
  methods: {
    // 关闭弹窗前操作
    handleClose() {
      this.$emit('close')
    },
    // 打开弹窗
    async open() {
      const loading = this.$loading({
        lock: true,
        spinner: 'el-icon-loading',
        background: 'rgba(255,255, 255, 0.7)',
        target: document.querySelector('.el-dialog__body')
      })
      // 请求数据
      const { data } = await this.$http.get(
        '/admin/user/edituser/id/' + this.uid
      )
      if (data) {
        if (data.code === 200) {
          this.editform = data.data
        } else {
          this.$message.error(this.getlang(data.msg))
        }
      }
      loading.close()
    },
    // 关闭弹窗
    closedialog() {
      this.$refs.editref.resetFields()
    },
    delrel(){
        this.editform.oauth = '0';
        this.editform.card = '';
        this.editform.email = '';
        this.editform.country = '';
        this.editform.realname="";
        this.editform.profession="";
        this.editform.contact_number="";
        this.editform.identity_card_front_image="";
        this.editform.identity_card_verso_image="";
    },
    // 提交操作
    async submit() {
      // this.editform.id = this.uid
      const { data } = await this.$http.post(
        '/admin/user/edituser',
        this.editform
      )
      if (data) {
        if (data.code === 200) {
          this.$message.success(this.$t('chenggong'))
          this.handleClose()
          this.$emit('update')
        } else {
          this.$message.error(this.getlang(data.msg))
        }
      }
    },
    // 图片上传成功操作
    uploadsfa(res) {
      this.editform.identity_card_front_image = res.data.url
    },
    uploadsfb(res) {
      this.editform.identity_card_verso_image = res.data.url
    },
    uploadsc(res) {
      this.editform.user_with_identity_card_image = res.data.url
    },
    uploadqt(res) {
      this.editform.other_image = res.data.url
    },
    caneditsf() {
      this.editsf = !this.editsf
    },
    caneditgj() {
      this.editgj = !this.editgj
    },
    canbeizhu() {
      this.editbz = !this.editbz
    }
  }
}
</script>
<style lang="less" scoped>
.el-alert {
  margin-top: 10px;
}
/*表单项宽度自适应  */
.el-date-editor--daterange.el-input,
.el-date-editor--daterange.el-input__inner,
.el-date-editor--timerange.el-input,
.el-date-editor--timerange.el-input__inner {
  width: 350px;
}
.el-date-editor.el-input,
.el-select > .el-input,
.el-date-editor.el-input__inner {
  width: 350px;
}
.el-form-item__content .el-input-group {
  width: 50%;
}
.mobile .el-form-item__content .el-input-group {
  width: 100%;
}
.mobile .el-date-editor--daterange.el-input,
.mobile .el-date-editor--daterange.el-input__inner,
.mobile .el-date-editor--timerange.el-input,
.mobile .el-date-editor--timerange.el-input__inner {
  width: 100%;
}
.mobile .el-date-editor.el-input,
.mobile .el-select > .el-input,
.mobile .el-date-editor.el-input__inner {
  width: 100%;
}
.avatar-uploader {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  width: 80px;
  height: 80px;
}
.avatar-uploader:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 80px;
  height: 80px;
  line-height: 80px;
  text-align: center;
}
.avatar {
  width: 80px;
  height: 80px;
  display: block;
}
.el-row {
  line-height: 30px;
}
</style>
